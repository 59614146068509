import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { AppConfig } from '@app/shared/model/app-config.model';

export const CarepointListServiceUrlToken = new InjectionToken('carepoint_list_service_url_token');
export const EnvUrlNameToken = new InjectionToken('env_url_name_token');

@Injectable({
  providedIn: 'root'
})
export class EnvConfigService {
  private settings: AppConfig;
  private settingsCache: Promise<AppConfig>;
  private readonly settingsUrl = 'assets/config/app-config.json';

  constructor(private http: HttpClient) { }

  public init(): Promise<AppConfig> {
    if (this.settingsCache) {
      return this.settingsCache;
    }
    this.settingsCache = this.http.get(this.settingsUrl).toPromise().then((data) => this.settings = data);
    return this.settingsCache;
  }

  getAuthorizationValue() {
    const date = new Date().toISOString();
    const salt = '12345';
    const signature = 'lgtesttv';
    return `HMAC-SHA256 apiKey=API_KEY, date=${date}, salt=${salt}, signature=${signature}`;
  }

  getSettings(): AppConfig {
    return this.settings;
  }
}
