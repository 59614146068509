import { LOCALE_ID } from '@angular/core';
import { AppInitService } from '@app/shared/service/app-init.service';
import { environment } from '@environments/environment';
import {
  defaultInterpolationFormat,
  I18NextLoadResult,
  I18NextModule,
  I18NEXT_SERVICE,
  ITranslationService
} from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

const i18nextOptions = {
  whitelist: ['en', 'es', 'iw'],
  fallbackLng: 'en',
  debug: environment.production,
  returnEmptyString: false,
  lng: document.dir && document.dir === 'rtl' ? 'iw' : navigator.language.split('-')[0],
  ns: ['translation', 'error'],
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
    escapeValue: false
  },
  backend: {
    loadPath: `locales/{{lng}}.{{ns}}.json?buster=${Date.now()}`,
  },
};

export function appInit(appLoadService: AppInitService, i18next: ITranslationService) {
  // TODO: remove app init from here
  return () => appLoadService.init().then(() => {
    const promise: Promise<I18NextLoadResult> = i18next
      .use(HttpApi)
      .use<any>(LanguageDetector)
      .init(i18nextOptions);
    return promise;
  });
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

export const configureI18Next = appInit;
