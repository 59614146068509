import { Subject } from 'rxjs';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AwFloatingDialogComponent } from '@aw-hospital/aw-components-lib/aw-floating-dialog';
import { Console } from '@aw/video-core';
import Feedback from '@aw/video-plugin-feedback';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { AwDialogConfig } from '@aw-hospital/aw-components-lib/src/interfaces';
import { Logger } from 'loglevel';
import { LOGGER_TOKEN } from '@app/shared/service/logger-token';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.less']
})
export class FeedbackComponent implements OnInit {
  @ViewChild(AwFloatingDialogComponent) dialog: AwFloatingDialogComponent;
  @ViewChild('modalContainer') modalContainerRef: ElementRef<any>;

  dialogHeader$: Subject<string> = new Subject<string>();

  thePlugin: Feedback;
  consoleArgs = {
    plugins: {
      Feedback: [Feedback, {
        containerContext: 'before_call'
      }],
    },
    events: undefined,
  };

  floatingDialogConfig: AwDialogConfig = {
    id: 'feedback-dialog',
    backdropClass: 'blured',
    panelClass: ['aw-modal', 'modal-sm'],
    autoFocus: false
  };

  floatingDialogButtonConfig = {
    buttonLabel: this.i18next.t('rating_send_feedback'),
    buttonClasses: 'btn-floating btn-large btn-primary',
    buttonIcon: 'feedback'
  };

  constructor(private renderer: Renderer2,
              @Inject(I18NEXT_SERVICE) private i18next: ITranslationService,
              @Inject(LOGGER_TOKEN) public log: Logger
  ) { }

  ngOnInit(): any {
    this.consoleArgs.events = this.createVideoConsoleEventHandlers();
    const theConsole = new Console(this.consoleArgs, 'videoConsoleNode');
    this.thePlugin = theConsole.plugins.find((p) => p.name === 'Feedback');

    return theConsole.start().then(() => {
      this.constructFloatingDialogModal();
    });
  }

  constructFloatingDialogModal(): void {
    const { modal_window, feedbackModalHeader } = this.thePlugin.feedbackModal.refs;

    this.dialogHeader$.next(feedbackModalHeader.innerText);

    this.thePlugin.on('feedback', () => {
      this.dialogHeader$.next(feedbackModalHeader.innerText);
    });

    this.thePlugin.on('dismiss', () => {
      this.dialog.closeDialog();
      this.dialogHeader$.next(feedbackModalHeader.innerText);
    });

    this.floatingDialogConfig = {
      ...this.floatingDialogConfig,
      ariaLabelledBy: feedbackModalHeader.id
    };

    this.renderer.appendChild(this.modalContainerRef.nativeElement, modal_window);
  }

  /**
   * Creates video console event handlers.
   */
  createVideoConsoleEventHandlers(): any {
    const instance = this;
    return {
      feedback(feedback) {
        instance.sendFeedback(feedback);
      }
    };
  }

  public sendFeedback(feedback: any) {
    try {
      const messageToSend: any = { feedback };
      // This will send the cached logs to log ingestor
      this.log.warn(messageToSend);
    } catch (err) {
      this.log.warn({ timeStamp: new Date(), message: `Failed to send log to log ingestor with message: ${err.message}` });
    }
  }
}
