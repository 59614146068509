<aw-progress-bar *ngIf="globalProgressBarIsVisible$ | async" @fadeInOut
                 class="global-progress-bar"
                 data-aw-id="global-progress-bar"
                 type="indeterminate"></aw-progress-bar>
<ng-container *ngIf="($('themeName') | async) as themeName">
  <div *ngIf="showThemeSwitcher" [class]="'theme-' + themeName">
    <aw-button styleClasses="btn-flat btn-large only-ic m-t2 neutral1-text" [ngStyle]="{
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 100
      }"
      [aw-aria-label]="(themeName === 'theme-dark' ? 'dark_mode' : 'light_mode') | i18next"
      (onClick)="toggleTheme()">
      <span class="material-icons-outlined m-r1" aria-hidden="true">
        {{ themeName === 'dark' ? 'dark_mode' : 'light_mode' }}
      </span>
    </aw-button>
  </div>
  <div *ngIf="branding" class="brand-background theme-{{ themeName }}">
    <img
      data-aw-id="brand-logo"
      class="brand-logo"
      [src]="$('logo.url') | async"
      [alt]="$('logoAltText') | async"/>
  </div>
</ng-container>
<div class="aw-ds no-bg" [ngClass]="{'centered-card-wrapper': layoutType === 'standAlone'}"
  [ngStyle]="{justifyContent: 'center'}">
  <ng-container [ngSwitch]="true">
    <!-- STANDALONE OR FULLSCREEN -->
    <ng-container *ngSwitchCase="fullScreen || layoutType === 'standAlone'">
      <router-outlet></router-outlet>
    </ng-container>
    <!-- RESPONSIVE -->
    <ng-container *ngSwitchCase="layoutType === 'responsive'">
      <aw-responsive-layout class="no-header"
        [mobileBackgroundUrl]="$('backgroundImageMobile.url') | async"
        [desktopBackgroundUrl]="$('backgroundImageDesktop.url') | async"
        type="Type2"
        mode="D">
        <router-outlet *awLayoutContent="'content'"></router-outlet>
        <ng-container *ngIf="footer">
          <aw-copyright *awLayoutContent="'footer'" styleClasses="small m-b0">
            <div class="m-b2" *ngIf="!configuration.hideAmwellElements">
              © {{ currentYear }} American Well Corporation. All rights
              reserved.
            </div>
            <a href="{{ helpUrl }}" target="_blank" [title]="'open_in_new_tab' | i18next">{{ 'need_help' | i18next
              }}<span class="visually-hidden-focusable">({{ 'open_in_new_tab' | i18next }})</span><span
                class="material-icons new-tab-icon" aria-hidden="true">open_in_new</span></a>
          </aw-copyright>
        </ng-container>
      </aw-responsive-layout>
    </ng-container>
    <!-- TODO LEGACY BY DEFAULT -->
    <ng-container *ngSwitchDefault>
      <div *ngIf="!fullScreen" class="centered-card-wrapper">
        <div class="centered-card">
          <aw-panel styleClasses="neutral1" [hidden]="shouldHideBox">
            <div class="panel-body" [ngClass]="classNames">
              <router-outlet></router-outlet>
            </div>
            <div *ngIf="footer" class="panel-footer neutral7-text fw-light fz-small">
              <div class="m-b2" *ngIf="!configuration.hideAmwellElements">
                {{ 'launch_visit_copyright' | i18next: { year: currentYear } }}
              </div>
              <a href="{{ helpUrl }}" target="_blank" [title]="'open_in_new_tab' | i18next">{{ 'need_help' | i18next
                }}<span class="visually-hidden-focusable">({{ 'open_in_new_tab' | i18next }})</span><span
                  class="material-icons new-tab-icon" aria-hidden="true">open_in_new</span></a>
            </div>
          </aw-panel>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="feedback-container" *ngIf="feedback">
    <app-feedback></app-feedback>
  </div>
</div>
