import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Params } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import 'url-search-params-polyfill';
import { Logger } from 'loglevel';
import { LOGGER_TOKEN } from '@app/shared/service/logger-token';

const NULL_TOKEN = { token: null };
const authCodeKey = 'auth_code';
const authUriKey = 'auth_uri';
const tokenKey = 'token';

@Injectable({
  providedIn: 'root'
})
export class TokenResolverService {
  tokens = {};
  authCode: string;
  constructor(private httpClient: HttpClient, @Inject(LOGGER_TOKEN) public log: Logger) { }

  // TODO: fix return type. Probably should remove  Promise<any> | any
  getToken(queryParams: Params | URLSearchParams): Observable<any> | Promise<any> | any {
    let authUri;
    let existingToken;

    if (typeof queryParams.get === 'function') {
      authUri = queryParams.get(authUriKey);
      this.authCode = queryParams.get(authCodeKey);
      existingToken = queryParams.get(tokenKey);
    } else {
      authUri = queryParams[authUriKey];
      this.authCode = queryParams[authCodeKey];
      existingToken = queryParams[tokenKey];
    }

    if (authUri?.length && this.authCode?.length && !existingToken?.length) {

      const cachedToken = this.tokens[this.authCode];
      if (cachedToken?.length) {
        console.log('Using cached token value');
        return of({ token: cachedToken });
      }

      const tokenUrl = `${decodeURIComponent(authUri)}/${this.authCode}`;
      return this.httpClient.get(tokenUrl).pipe(
        map((data: any) => data?.token?.length ? data : NULL_TOKEN),
        tap((data: any) => {
          if (data?.token?.length) {
            console.log(`token retrieved successfully using the auth_code`);
            this.tokens[this.authCode] = data?.token;
            sessionStorage.setItem('aw-bearer-token', data?.token);
          } else {
            console.error(`token could not be retrieved for the given auth_code`);
          }
        }),
        catchError(this.handleError)
      );

    }
    return of(NULL_TOKEN);
  }

  private handleError(error: HttpErrorResponse) {
    if (error?.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(`An error occurred while trying to retrieve the token`);
      this.log.error(`An error occurred while trying to retrieve the token:`, error?.error?.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.log.error(
        `Api returned an error while trying to retrieve the token:` +
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return of(NULL_TOKEN);
  }

  parseToken(token: string): { [prop: string]: any } {
    try {
      return jwt_decode(token);
    }
    catch (err) {
      return null;
    }
  }
}
