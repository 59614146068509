/*!
 * American Well Online Care
 *
 * Copyright © 2021 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { EnvConfigService } from '@app/shared/service/env-config.service';
import * as log from 'loglevel';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private headers: HttpHeaders;
  private logger: log.RootLogger;
  private isClientSideLogEnabled: boolean;
  private prefix: any;
  private originalFactory: log.MethodFactory;
  private sendQueue: string[];
  private isSending: boolean;
  private readonly twilioMessages = ['Created a new Participant:',
    'A new RemoteParticipant connected:', 'RemoteParticipant disconnected:'];

  constructor(
    private httpClient: HttpClient,
    private ngZone: NgZone,
    @Inject(EnvConfigService) private envConfigService: EnvConfigService
  ) {
    this.headers = new HttpHeaders({ 'Content-type': 'application/json' });
  }

  /**
   * Extend loglevel with new plugin which will send log information to the log-sever
   * Adapted from loglevelServerSend - https://github.com/artemyarulin/loglevel-serverSend
   *
   * LoggingService.send(log,{url:'https://example.com/app/log',prefix: function(logSev,message) {
   *     return '[' + new Date().toISOString() + '] ' + logSev + ': ' + message + '\n'
   * }})
   */
  public send(logger: log.RootLogger, options: any): void {
    if (!logger || !logger.methodFactory) {
      throw new Error('loglevel instance has to be specified in order to be extended');
    }

    this.logger = logger;
    this.isClientSideLogEnabled = options && options.isClientSideLogEnabled || false;
    this.prefix = options && options.prefix;
    this.originalFactory = this.logger.methodFactory;
    this.sendQueue = [];
    this.isSending = false;

    this.logger.methodFactory = (methodName, logLevel) => {
      return this.loggerMethodFactory(methodName, logLevel);
    };

    this.logger.setLevel(this.logger.levels.WARN);
  }

  /**
   * Adding sending browser console log message to the server-side
   */
  public decorateBrowserConsoleMassages(): void {
    // TODO add log levels configurable
    const methods = ['trace', 'error', 'warn', 'info', 'log', 'trace'];


    methods.forEach(meth => {
      const orig = console[meth].bind(console);
      console[meth] = function(...args) {
        const logArgs = args
          .map((logArg) => {
            let stringifiedItem = logArg;
            if (logArg !== null && typeof logArg === 'object') {
              // commenting out util.inspect since we are not using its return value and causing video processor to explode
              // util.inspect(logArg);
              try {
                stringifiedItem = JSON.stringify(logArg);
              } catch (err) {
                // If there is an error then don't include that log.
                return;
              }
            }
            return stringifiedItem;
          });
        let message: any = logArgs;
        if (meth === 'error') {
          const stack = args.filter(arg => arg && arg instanceof Error)[0]?.stack || null;
          if (stack) {
            message = logArgs.join(',') + 'STACK_TRACE:' + stack;
          }
        }
        log[meth](message);
        orig(...args);
      };
    });
  }

  /* Loglevel API entry method called each time the log level is set. */
  public loggerMethodFactory(methodName: string, logLevel: log.LogLevelNumbers) {
    const originalFactory: log.LoggingMethod = this.originalFactory(methodName, logLevel, `${environment.name}`);
    return (...messages: string[]) => {
      const clientSideLogMessage: string = this.createClientSideLogMessage(...messages);

      if (this.isClientSideLogEnabled) {
        originalFactory(clientSideLogMessage);
      }

      // If an object send as is otherwise format the string.
      const messageForService = typeof messages[0] === 'object' ? messages[0] : clientSideLogMessage.replace(/"/g, '\'');
      const serverSideLogMessage: string = this.prefix(methodName, messageForService);

      // Do not send log messages coming from twilio that includes Participant's identity
      if (!this.verifyTwilioMessages(serverSideLogMessage)) {
        this.sendQueue.push(serverSideLogMessage);
      }
      this.ngZone.runOutsideAngular(() => {
        this.sendNextMessage();
      });
    };
  }

  /* Sends the next client log message to the server-side */
  public sendNextMessage(): void {
    if (!this.sendQueue || !this.sendQueue.length || this.isSending) {
      return;
    }
    const nextMessage = this.sendQueue.shift();
    if (nextMessage === null) {
      return;
    }
    this.isSending = true;
    this.httpClient.post(this.envConfigService.getSettings().logging.loggingServiceUrl + '/api/v1/logMessage',
      nextMessage, { headers: this.headers })
      .toPromise()
      .then(() => {
        setTimeout(() => this.sendNextMessage(), 0);
      })
      .finally(() => {
        this.isSending = false;
      });
  }

  /*
   * Creates the message to log on the client side console log.
   * If the message is an object, it returns the object as a JSON string
   */
  public createClientSideLogMessage(...messages: string[]): string {
    return messages.map((message: any) => {
      if (typeof message === 'string' || message instanceof String
        || typeof message === 'number' || message instanceof Number
        || typeof message === 'boolean' || message instanceof Boolean) {
        return message;
      }
    }).join(' ');
  }

  /**
   * Verifies if the serverSideLogMessage includes specific messages coming from Twilio
   * returns true if messages are contained in serverSideLogMessage otherwise returns false
   */
  public verifyTwilioMessages(serverSideLogMessage: string): boolean {
    if (serverSideLogMessage) {
      return !!this.twilioMessages.find(msg => serverSideLogMessage.includes(msg));
    }
    return true;
  }
}
